@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2 {
    animation-delay: 2s;
  }
  .animation-delay-4 {
    animation-delay: 4s;
  }
}

$small: 0px;
$large: 1920px;

$s: 100vw;
@media (min-aspect-ratio: 1/1) {
  $s: 100vh;
}

@function between($from, $to, $fromWidth, $toWidth) {
  $slope: ($to - $from) / ($toWidth - $fromWidth);
  $base: $from - $slope * $fromWidth;

  @return calc(#{$base} + #{$s * $slope});
}

html {
  font-size: between(2px, 20px, $small, $large);
}
